import React, { useEffect, useState } from "react";

import "../style/newsletter.css";

import Page from "../components/page";
import TextField from "../components/textField";
import Button from "../components/button";
import { getNewsletterCount, getNewsletterData, sendTestNewsletter, updateNewsletterData } from "../content/newsletterManager";
import { openPopUp } from "../components/popUp";
import { openActionPage } from "../components/actionPage";
import ConfirmNewsletterAction from "./action/confirmNewsletter";

import "../style/dashboardSendNewsletter.css";
import { validateEmail } from "../utils/generalUtils";
import { useNavigate, useParams } from "react-router-dom";

import SaveButton from "../components/saveButton";
import NewsletterGeneral from "./newsletter/newsletterGeneral";
import { setUnsafedChanges } from "../content/unsafedChanges";
import { jsonEmpty } from "../utils/generalUtils";

import "../style/newsletter.css";
import NewsletterEvents from "./newsletter/newsletterEvents";
import NewsletterArticles from "./newsletter/newsletterArticles";

function submitNewsletterChange(key, value) {
    var json = {};
    json[key] = value;
    submitNewsletterChanges(json);
}

function submitNewsletterChanges(json) {
    const event = new CustomEvent("updateNewsletterData", { detail: json});
    document.dispatchEvent(event);
}

export default function DashboardSendNewsletterPage(props) {

    const [count, setCount] = useState(0);
    const [data, setData] = useState({});
    const [newData, setNewData] = useState({});

    const navigate = useNavigate();

    const active = props.active;

    const {id} = useParams();

    const changeMade = !jsonEmpty(newData);
    function hasUnsavedChanges() {
        return !jsonEmpty(newData);
    }

    function getNewData() {
        return newData;
    }

    useEffect(() => {
        getNewsletterCount(setCount);
    })

    useEffect(() => {
        if(jsonEmpty(data)) {
            getNewsletterData((d) => {
                setData(d);
            });
        }

        document.addEventListener("updateNewsletterData", (e) => {
            var newDataTemp = JSON.parse(JSON.stringify(newData));
            for(var i = 0; i < Object.keys(e.detail).length; i++) {
                var key = Object.keys(e.detail)[i];
                var value = e.detail[key];
                newDataTemp[key] = value;
                if(value == data[key]) {
                    
                    delete newDataTemp[key];
                }

            }
            setNewData(newDataTemp);
            setUnsafedChanges(!jsonEmpty(newDataTemp));
        });
    });

    const combinedData = {};
    for(var key in data) {
        if(newData.hasOwnProperty(key)) {
            combinedData[key] = newData[key];
        }else {
            combinedData[key] = data[key];
        }
    }

    if(Object.keys(combinedData).length == 0) return <></>;

    

    return <Page id="newsletter" className={"header-page" + (active != 0 ? " fullsize" : "")}>
        <div className="main-box">
            <div className="header">
                <a onClick={() => {navigate("/newsletter/general")}} className={active == 0 ? "active" : ""}>Allgemein</a>
                <a onClick={() => {navigate("/newsletter/events")}} className={active == 1 ? "active" : ""}>Termine</a>
                <a onClick={() => {navigate("/newsletter/articles")}} className={active == 2 ? "active" : ""}>Beiträge</a>
            </div>
            <div className="content">
                {active == 0 ? <NewsletterGeneral data={combinedData}/> : <></>}
                {active == 1 ? <NewsletterEvents data={combinedData}/> : <></>}
                {active == 2 ? <NewsletterArticles data={combinedData}/> : <></>}
            </div>
            <SaveButton onClick={() => {
                if(!changeMade) return;
                updateNewsletterData(newData, () => {
                    openPopUp("Newsletter erfolgreich aktualisiert");
                    setData({});
                    setNewData({});
                    setUnsafedChanges(false);
                });
            }} changeMade={changeMade}/>
        </div>
    </Page>;
}

export {submitNewsletterChange}