import React, {useState} from "react";

import "../style/switch.css";

export default function Switch(props) {

    const [active, setActive] = useState(props.defaultValue != undefined? props.defaultValue : 0);

    console.log(props.defaultValue);
    return <div className="switch">
        <a className={active == 0 ? "active" : ""} onClick={() => {
            setActive(0);
            if(props.onChange) {
                
            props.onChange(0);
            }
        }}>{props.left}</a>
        <a className={active == 1 ? "active" : ""} onClick={() => {
            setActive(1);
            if(props.onChange) {
                props.onChange(1);
            }
        }}>{props.right}</a>
    </div>;
}