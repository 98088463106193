import { openPopUp } from "../components/popUp";
import { sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

function getNewsletterCount(onFinish) {

    sendRequest("newsletter/count/normal", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.count);
        }else {
            console.log(r);
        }
    }, getSessionId());
}

function sendTestNewsletter(mail, onFinish) {
    sendRequest("newsletter/config/send/" + mail, "POST", {}, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp("Ein Fehler ist aufgetreten");
            console.log(r);
        }
    }, getSessionId());
}

function getNewsletterData(onFinish) {
    sendRequest("newsletter/config/data", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data);
        }else {
            openPopUp("Ein Fehler ist aufgetreten");
            console.log(r);
        }
    }, getSessionId());
}

function updateNewsletterData(data, onFinish) {
    sendRequest("newsletter/config/data", "POST", data, (r) => {
        if(r.status == 200) {
            onFinish(r.data);
        }else {
            openPopUp("Ein Fehler ist aufgetreten");
            console.log(r);
        }
    }, getSessionId());
}

export {getNewsletterCount, sendTestNewsletter, getNewsletterData, updateNewsletterData}