import React from "react";

import IconButton from "../../components/iconButton";
import ActivateIcon from "../../assets/icons/activate";
import DeactivateIcon from "../../assets/icons/deactivate";
import TextField from "../../components/textField";
import { submitNewsletterChange } from "../dashboardSendNewsletter";
import ChooseListElement from "../../components/chooseListElement";
import { formatDate } from "../../content/apiManager";


export default function NewsletterEvents(props) {
    const disabledEvents = props.data.disabled_events.split(";");
    return <div className="newsletter-events">
        <div className="event-list">
            {props.data.events.map(e => {
                var nextMonthNumber = (new Date().getMonth()+1)%12 + 1;
                if(new Date(e.startTime).getMonth()+1 != nextMonthNumber) return false;
                return <ChooseListElement onChange={(v) => {
                    var events = disabledEvents;
                    if(!v) {
                        events.push(e.id);
                    }else {
                        const index = events.indexOf(e.id + "");
                        if (index > -1) { // only splice array when item is found
                            events.splice(index, 1); // 2nd parameter means remove one item only
                        }
                    }
                    var output = "";
                    for(var i = 0; i < events.length; i++) {
                        output += events[i] + ";";
                    }
                    if(output.length > 0) {
                        output = output.substring(0, output.length - 1);
                    }
                    submitNewsletterChange("disabled_events", output);
                }} defaultValue={!disabledEvents.includes(e.id + "")} image={e.imageLink} title={e.title} date={formatDate(new Date(e.startTime))}/>;
            })}
            
        </div>
    </div>;
}