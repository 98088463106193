import React, { useState, useEffect } from "react";
import Button from "../components/button";
import Checkbox from "../components/checkbox";
import TextField from "../components/textField";
import { getCategory, loadUser } from "../content/loginManager";

import "../style/login.css";

import { login } from "../content/loginManager";
import { getHeaderElements, openFirstHeaderLink } from "../components/header";

function LoginPage() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [stayLoggedIn, setStayLoggedIn] = useState(false);

    function executeLogin() {
        login(username, password, stayLoggedIn, () => {
            var headerElements = getHeaderElements(getCategory());
            openFirstHeaderLink(getCategory());
        });
    }

    return <div id="login">
        <div className="form">
            <div className="content">
                <h1>Willkommen zurück!</h1>
                <TextField placeholder="Benutzername eingeben" onChange={(v) => {setUsername(v)}} label="Benutzername" onEnter={executeLogin}/>
                <TextField placeholder="Passwort eingeben" onChange={(v) => {setPassword(v)}} label="Passwort" password onEnter={executeLogin}/>
                <Checkbox clickable label="angemeldet bleiben" onChange={setStayLoggedIn}/>
                <Button onClick={executeLogin}>Anmelden</Button>
            </div>
            <div className="info">
                <p>Probleme beim Anmelden? <a href="mailto:info@bergamlaimer.info">Admin kontaktieren</a></p>
            </div>
        </div>
        <div className="picture">

        </div>
    </div>;
}

export default LoginPage;