import React from "react";
import EditImageBox from "./editImageBox";

import {useNavigate} from "react-router-dom";

import "../style/eventListElement.css";
import PenIcon from "../assets/icons/pen";
import DeleteIcon from "../assets/icons/delete";
import CopyIcon from "../assets/icons/copy";
import { openActionPage } from "./actionPage";
import DeleteEventAction from "../pages/action/deleteEvent";
import { getCategory } from "../content/loginManager";

export default function EventListElement(props) {

    const navigate = useNavigate();

    return <div className="event-list-element">
        <div className="info">
            
            <EditImageBox link={props.image} notclickable/>
            <p>{props.date}</p>
            <p className="title">{props.title}<p>{getCategory() == "SUPERADMIN" || getCategory() == "ADMIN" ? props.user : ""}</p></p>
            <div className="action-buttons">
                <div className="action" onClick={props.onEdit}>
                    <PenIcon/>
                    <a>bearbeiten</a>
                </div>
                <hr/>
                <div className="action" onClick={props.onDelete}>
                    <DeleteIcon/>
                    <a>löschen</a>
                </div>
                <hr/>
                <div className="action" onClick={props.onCopy}>
                    <CopyIcon/>
                    <a>kopieren</a>
                </div>
            </div>
        </div>
    </div>

}