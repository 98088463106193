import React from "react";
import ArrowBigIcon from "../assets/icons/arrowBig";

import "../style/createArticleListElement.css";
import IconButton from "./iconButton";
import ImageBox from "./imageBox";

import CreateParagraphAction from "../pages/action/createParagraph";
import { openActionPage } from "./actionPage";
import PenIcon from "../assets/icons/pen";

const layoutOptions = ["text-only", "img-left", "img-right", "text-center", "three-img", "img-fs"];

export default function CreateArticleListElement(props) {

    var layoutOption = -1;
    if(!props.imgs || props.imgs.length == 0) {
        layoutOption = 0;
    }else if(props.imgs.length == 1) {
        if(!props.text || props.text.length == 0) {
            layoutOption = 5;
        }else if(props.attributes.alignImages == "right") {
            layoutOption = 2;
        }else {
            layoutOption = 1;
        }
    }else if(props.imgs.length == 2) {
        layoutOption = 3;
    }else if(props.imgs.length == 3) {

        layoutOption = 4;
    }

    return <div className="paragraph article-list-el">
        <div className="arrows">
            <IconButton disabled={!props.left} onClick={() => {props.onMove(true)}}><ArrowBigIcon/></IconButton>
            <IconButton disabled={!props.right} onClick={() => {props.onMove(false)}}><ArrowBigIcon/></IconButton>
        </div>
        <div className="paragraph-right">
            
            <div className={"paragraph-content " + layoutOptions[layoutOption]}>
                <p className="text">{props.text ? props.text.split("\n").map(e => {
                    return <p>{e}</p>
                }) : <></>}</p>
                {props.imgs ? props.imgs.map(e => {
                    return <ImageBox image={e.getLink()} fullscreen={e.isFullscreen()}/>
                }): <></>}
            </div>
            <div className="hover" onClick={() => {
                openActionPage(<CreateParagraphAction formatOption={layoutOption} onFinish={props.onChange} images={props.imgs} text={props.text}/>);
            }}>
                <div className="inner">
                <PenIcon/>
                <a>Absatz bearbeiten</a>

                </div>
            </div>
        </div>
    </div>;
}