import React, {useEffect, useRef, useState} from "react";

import "../style/checkbox.css";

export default function Checkbox(props) {

    const inputEl = useRef();
    const firstExecute = useRef(false);
    const [checked, setChecked] = useState(false);

    const [defaultValue, setDefaultValue] = useState(props.defaultValue);

    useEffect(() => {
        if(props.defaultValue && !firstExecute.current) {
            inputEl.current.defaultValue = props.defaultValue;
            setChecked(props.defaultValue);
            firstExecute.current = true;
        }
        if(defaultValue != props.defaultValue) {

            inputEl.current.defaultValue = props.defaultValue;
            setDefaultValue(props.defaultValue);
            setChecked(props.defaultValue);
        }
    });

    return <div class={"checkbox checkbox-wrapper-46" + (checked ? " checked" : "") + (props.className ? " " + props.className : "")}>
            <input ref={inputEl} onClick={(e) => {props.onChange(!checked);
            if(props.clickable == true) {
                setChecked(!checked);
            }else {
                e.preventDefault();
            }}} class="inp-cbx" id="cbx-46" type="checkbox" />
            <label class="cbx" for="cbx-46"><span>
            <svg width="12px" height="10px" viewbox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </svg></span><span>{props.label}</span>
            </label>
        </div>;

}