export default function AddIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="99" height="62" viewBox="0 0 99 62">
    <g id="Gruppe_15" data-name="Gruppe 15" transform="translate(-702 -889)">
      <g id="Gruppe_6" data-name="Gruppe 6" transform="translate(41.104 238)">
        <rect id="Rechteck_9" data-name="Rechteck 9" width="99" height="62" rx="31" transform="translate(660.896 651)"/>
      </g>
      <g id="Gruppe_12" data-name="Gruppe 12" transform="translate(41)">
        <line id="Linie_9" data-name="Linie 9" y2="27" transform="translate(710.5 906.5)" fill="none" stroke="#fff" stroke-width="3"/>
        <line id="Linie_10" data-name="Linie 10" y2="27" transform="translate(724 920) rotate(90)" fill="none" stroke="#fff" stroke-width="3"/>
      </g>
    </g>
  </svg>
;  
}