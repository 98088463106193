export default function CopyIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" viewBox="0 0 30 27">
    <g id="copy" transform="translate(-1664.981 -405)">
      <g id="Rechteck_111" data-name="Rechteck 111" transform="translate(1673.981 405)" fill="none" stroke="#000" stroke-width="2">
        <rect width="21" height="21" rx="3" stroke="none"/>
        <rect x="1" y="1" width="19" height="19" rx="2" fill="none"/>
      </g>
      <g id="Rechteck_112" data-name="Rechteck 112" transform="translate(1664.981 409)" fill="none" stroke="#e9e9e9" stroke-width="2">
        <rect width="24" height="23" rx="3" stroke="none"/>
        <rect x="1" y="1" width="22" height="21" rx="2" fill="none"/>
      </g>
      <g id="Rechteck_110" data-name="Rechteck 110" transform="translate(1665.981 410)" fill="#e9e9e9" stroke="#000" stroke-width="2">
        <rect width="21" height="21" rx="3" stroke="none"/>
        <rect x="1" y="1" width="19" height="19" rx="2" fill="none"/>
      </g>
    </g>
  </svg>;
}