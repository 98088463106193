import React, {useEffect, useRef, useState} from "react";

import DisabledEyeIcon from "../assets/icons/disabledEye";
import EyeIcon from "../assets/icons/eye";
import Checkbox from "./checkbox";

import "../style/textField.css";
import { generatePassword } from "../utils/generalUtils";

export default function TextField(props) {

    const [visible, setVisible] = useState(false);
    const inputEl = useRef();

    useEffect(() => {
        
        if(props.autogrow) {
            inputEl.current.parentNode.dataset.replicatedValue = inputEl.current.value + "\n\n";
        }

        window.addEventListener("textFieldGenerate", (e) => {
            if(props.recieveGenerate || props.generateButton) {
                if(!inputEl.current) return;
                inputEl.current.value = e.detail.text;
                props.onChange(e.detail.text);
            }
        });
    });

    const inputProps = {
        ref: inputEl,
        defaultValue: props.defaultValue,
        placeholder: props.placeholder ? props.placeholder : "",
        onChange: (e) => {
            props.onChange(e.target.value);
            if(props.autogrow) {
                e.target.parentNode.dataset.replicatedValue = e.target.value;
            }
        },
        type: props.time ? "time" : props.date ? "date" : props.password && !visible ? "password" : "text"
    }

    const mainEl = React.cloneElement(props.textarea ? <textarea/> : <input/>, inputProps);

    return <div className={"text-field" + (props.second ? " second" : "") + (props.autogrow ? " autogrow" : "") + (props.className ? " " + props.className : "")} onKeyPress={(e) => {
        if(e.key == "Enter") {
            if(props.onEnter) {
                props.onEnter();
            }
        }
    }}>
        {props.labelCheckbox ? <Checkbox clickable onChange={props.labelCheckboxOnChange}/> : <></>}{props.label != undefined ? <p>{props.label}{props.generateButton ? <a className="generate unselectable" onClick={() => {
            const event = new CustomEvent("textFieldGenerate", {
            detail: {
                text: generatePassword()
            },
            bubbles: true,
            cancelable: true,
            composed: false,
            });
            window.dispatchEvent(event);
        }}>zufällig generieren</a> : <></>}</p> : <></>}
        {mainEl}
        {props.password ? <div className="icon" onClick={() => {
            setVisible(!visible);
        }}>{visible ? <DisabledEyeIcon/> : <EyeIcon/>}</div> : <></>}
    </div>
}