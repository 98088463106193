import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom"
import SingleAddIcon from "../../assets/icons/singleAdd";
import { openActionPage } from "../../components/actionPage";
import AddPicture from "../../components/addPicture";
import CreateDashboardPage from "../../components/createDashboardPage";
import EditImageBox from "../../components/editImageBox";

import TextField from "../../components/textField";
import AddImageAction from "../action/addImage";
import EditImageAction from "../action/editImage";

function DashboardCreateEventGeneral(props) {

    const [image, setImage] = useState(props.data.image);
    const [imageAttributes, setImageAttributes] = useState(props.data.imageAttributes ? props.data.imageAttribute.split(",") : undefined);

    return <>
        <div className="text-fields">
            
            <TextField defaultValue={props.data.title} label="Titel" placeholder="Titel eingeben" onChange={(v) => {
                props.submit("title", v);
            }}/>
            <TextField label="Ort" placeholder="91676 München Kreillerstr. 33" defaultValue={props.data.location} onChange={(v) => {
                props.submit("location", v);
            }}/>
        </div>
        <div className="choose-image">
            <p>Bild</p>
            
            {image ? <EditImageBox onClick={() => {
                openActionPage(<EditImageAction onDelete={() => {
                    props.submitMultiple({
                        "image": null,
                        "imageAttributes": null
                        });
                    setImage();
                    setImageAttributes();
                }} onChange={(f, d) => {
                    var newImageAttributes = (f == 1) + "," + d;
                    props.submit("imageAttributes", newImageAttributes);
                    setImageAttributes(newImageAttributes.split(","));
            }}  image={image} attributes={imageAttributes}/>);
            }} editText="Bild bearbeiten" link={image}/> : <div onClick={() => {
                openActionPage(<AddImageAction onFinish={(v) => {
                    const defaultImageAttributes = "false,Bild";
                    props.submitMultiple({
                        "image": v,
                        "imageAttributes": defaultImageAttributes
                        });
                    setImage(v);
                    setImageAttributes(defaultImageAttributes);
                }}/>);
            }} className="add-img">
                <SingleAddIcon/>
                <a>Bild hinzufügen</a>
            </div>}
        </div>
        <TextField autogrow textarea label="Beschreibung" placeholder="Um was geht es bei deinem Termin?" defaultValue={props.data.description} onChange={(v) => {
                props.submit("description", v);
            }}/>
    </>;
}

export default DashboardCreateEventGeneral;