import React, { useEffect, useRef, useState } from "react";
import {useNavigate} from "react-router-dom";
import Page from "../components/page";
import SingleAddIcon from "../assets/icons/singleAdd";
import Button from "../components/button";
import { getClubCategories, registerClub } from "../content/clubManager";
import MultipleSelect from "../components/multipleSelect";
import { openPopUp } from "../components/popUp";

export default function DashboardCreateOwnClub(props) {

    const navigate = useNavigate();
    const [category, setCategory] = useState(-1);
    const [categories, setCategories] = useState([]);
    const firstExecute = useRef(false);

    useEffect(() => {
        if(!firstExecute.current) {
            
            getClubCategories(setCategories);
            firstExecute.current = true;
        }
    });

    return <Page className="simple-page">
        <div className="main-box">
            <h1>Vereinsseite</h1>
            <p className="info">Dein Verein hat noch keine Vereinsseite.</p>
            <p className="info">Bitte beachten, dass neu erstellte Veriensseiten standartmäßig versteckt sind.</p>
            <p className="info">Erst wenn du sie eingeblendet hast, ist deine Seite für andere sichtbar.</p>
            <MultipleSelect items={categories} onChange={setCategory} label="Kategorie"/>
            <Button invert thin onClick={() => {
                if(category == -1) {
                    openPopUp("Wähle zunächst eine Kategorie aus");
                    return;
                }
                registerClub(categories[category], (r) => {
                    navigate("/club/" + r.id + "/general");
                });
            }}>Vereinsseite erstellen<SingleAddIcon/></Button>
        </div>
    </Page>;
}