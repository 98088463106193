import React, { useEffect, useRef, useState } from "react";
import {useNavigate} from "react-router-dom";
import Header from "../components/header";
import Dashboard from "./dashboard";
import Page from "../components/page";
import Button from "../components/button";
import AddIcon from "../assets/icons/add";
import TextField from "../components/textField";
import "../style/dashboardAccounts.css";
import AccountBox from "../components/accountBox";
import { getAccounts, getAccountTypes } from "../content/accountManager";
import IconButton from "../components/iconButton";
import SingleAddIcon from "../assets/icons/singleAdd";
import SearchIcon from "../assets/icons/search";
import SearchHeader from "../components/searchHeader";

function DashboardAccounts() {

    const navigate = useNavigate();

    const calledOnce = useRef(false);
    const [boxes, setBoxes] = useState([]);

    const [search, setSearch] = useState("");

    

    useEffect(() => {
        if(!calledOnce.current) {
            var newBoxes = []
            getAccounts((r) => {
                r.map(e => {
                    if(e.type == "SUPERADMIN") return;
                    if(!e.name.includes(search)) return;
                    console.log(e)
                    newBoxes.push(<AccountBox id={e.id} online={e.online} disabled={!e.enabled} name={e.name} type={getAccountTypes()[e.type]}/>);
                });
                calledOnce.current = true;
                setBoxes(newBoxes);
            })
        }
    });

    return <Page id="dashboard-accounts">
        <div className="main-box">
            <SearchHeader setSearch={(v) => {
                    setSearch(v);
                    calledOnce.current = false;
                }} button={
                <Button medium noinvert onClick={() => {
                    navigate("/accounts/create");
                }}><AddIcon/></Button>}/>
            {boxes.length == 0 ? <h2 className="empty">Es sind noch keine Benutzeraccounts vorhanden</h2> : <></>}
            <div className="content content-box">{
                    boxes
                }
            </div>
        </div>
    </Page>

}

export default DashboardAccounts;