import React, { useRef, useState } from "react";
import { useEffect } from "react";

import "../style/actionPage.css";
import "../style/loader.css"

import CloseIcon from "../assets/icons/close";
import UploadIcon from "../assets/icons/upload";
import { openPopUp } from "./popUp";

function ActionPage(props) {

    const boxRef = useRef();
    const overlayRef = useRef();
    const firstExecute = useRef(false);
    const [drag, setDrag] = useState(false);
    const [dragChildren, setDragChildren] = useState(false);
    const [height, setHeight] = useState(0);

    useEffect(() => {

        
        window.ondrop = function(e) { e.preventDefault(); return false };
        
        setTimeout(() => {
            
        document.addEventListener("mousedown", (e) => {
            if(!boxRef.current) return;
                if(!elementIsClicked(e, boxRef.current)) {
                    closeActionPage();
                }
            });
        }, 500);

        document.addEventListener("keyup", (e) => {
            if (e.key === "Escape") {
                closeActionPage();
            }
          });

          if(props.draganddrop) {
            boxRef.current.addEventListener("dragenter", () => {
                setDrag(true);
              });
    
              overlayRef.current.addEventListener("dragover", (e) => {
                if(!drag) {
                    
                    setDrag(true);
                }
                e.preventDefault();
              })
            overlayRef.current.addEventListener("dragleave", (e) => {
                setTimeout(() => {
                    if(!dragChildren) {
                        setDrag(false);
                    }
                }, 10);
                e.preventDefault();
            });
            overlayRef.current.addEventListener("drop", (e) => {
                setDrag(false);
                e.preventDefault();
                if(e.dataTransfer && e.dataTransfer.files.length != 0){
                    var files = e.dataTransfer.files //Array of filenames
                    if(files.length > 1) {
                        openPopUp("Bitte immer nur 1 Datei hochladen");
                        return;
                    }
                    props.onDrop(files[0]);
            
                }else{
                    openPopUp("Der Browser unterstützt Drag and drop nicht");
                }
            });
            var children = overlayRef.current.children;
            for (var i = 0; i < children.length; i++) {
                var child = children[i];
                child.addEventListener("dragenter", (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                })
                child.addEventListener("dragleave", (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                })
            }
          }
        if(!firstExecute.current) {
            setHeight(boxRef.current.getBoundingClientRect().height);
            firstExecute.current = true;
        }
    })

    return <>
    <div className="action-page-overlay"/>
    <div className={"action-page" + (props.className ? " " + props.className : "") + (props.big ? " big" : "")}>
        <div className={"page-box"} ref={boxRef}>
            <div className="close" onClick={() => {
                closeActionPage();
                if(props.onCancel) {
                    props.onCancel();
                }
            }}>
                <CloseIcon/>
            </div>
            {props.children}
        </div>
            
        <div ref={overlayRef} className={"page-box overlay" + (drag ? " drag" : "")} style={{
            height: height + "px"
        }}>
            <UploadIcon/>
            <a>Loslassen, um Bild hochzuladen</a>
        </div>
        <div className={"page-box overlay" + (props.loading ? " loading" : "")} style={{
            height: height + "px"
        }}>
            <span class="loader"></span>
        </div>
    </div></>;
}

function openActionPage(page) {
    const event = new CustomEvent("openActionPage", { detail: {
        page: page
    } });
    document.dispatchEvent(event);
}

function closeActionPage() {
    document.dispatchEvent(new CustomEvent("closeActionPage"));
}

function elementIsClicked(clickEvent, element) {

    var x = clickEvent.clientX, y = clickEvent.clientY;
    var left = element.getBoundingClientRect().left, top = element.getBoundingClientRect().top;
    var maxLeft = left + element.offsetWidth, maxTop = top + element.offsetHeight;

    return (x >= left && x <= maxLeft) && (y >= top && y <= maxTop);

    console.log(element.getBoundingClientRect().left);
}

export default ActionPage;
export {closeActionPage, openActionPage}