import ActionPage from "../../components/actionPage";

import { closeActionPage } from "../../components/actionPage";
import Button from "../../components/button";
import { deleteEvent } from "../../content/eventManager";

export default function DeleteEventAction(props) {
    return <ActionPage>
    <p>Bist du sicher, dass du diesen Termin löschen möchtest? Zum Bestätigen drücke auf "Löschen", um den Vorgang abzubrechen, schließe das Fenster über das X oben rechts.</p>
    <Button onClick={() => {
        deleteEvent(props.id, () => {
            closeActionPage();
            document.dispatchEvent(new CustomEvent("reloadEvents"));
        });
    }}>Löschen</Button>
    </ActionPage>;
}