import React, { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import { getSessionId, checkSession } from "../content/loginManager";
import DeleteAccountAction from "./action/deleteAccount";

function Dashboard(props) {

    const sessionCheckStarted = useRef(false);
    const [currentActionPage, setCurrentActionPage] = useState(<></>);

    useEffect(() => {
        document.addEventListener("openActionPage", (e) => {
            setCurrentActionPage(e.detail.page);
        });
        document.addEventListener("closeActionPage", () => {
            setCurrentActionPage(<></>);
        });

        if(!sessionCheckStarted.current) {
            sessionCheckStarted.current = true;
            setInterval(recheckSession, 1000 * 60);
        }
    });

    function recheckSession() {
        checkSession(getSessionId(), (v) => {
            if(!v) {
                window.open("/login", "_self");
            }
        });
    }

    return <div id="dashboard">
        <Header defaultActive={props.defaultActive}/>
        {currentActionPage}
        {props.children}
    </div>

}

export default Dashboard;