function getDateFromDateAndTimeString(date, time) {

    return new Date(date.split("-")[0], Math.floor(date.split("-")[1]) - 1, date.split("-")[2], time.split(":")[0], time.split(":")[1]);
}

function getDateFromNormalDateAndTimeString(date, time) {

    return getDateFromNormalDateAndTimeStringAddDays(date, time, 0);
}

function getDateFromNormalDateAndTimeStringAddDays(date, time, days) {

    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days, time.split(":")[0], time.split(":")[1]);
}

function getDateFromDateAndTimeStringAddDays(date, time, days) {

    return new Date(date.split("-")[0], Math.floor(date.split("-")[1]) - 1, Math.floor(date.split("-")[2]) + days, time.split(":")[0], time.split(":")[1]);
}

function getDateFromDate(date) {
    return date.getFullYear() + "-" + addZero(date.getMonth()+1) + "-" + addZero(date.getDate());
}

function getTimeFromDate(date) {
    return addZero(date.getHours()) + ":" + addZero(date.getMinutes());
}

function formatDate(date) {
    return addZero(date.getDate()) + "/" + addZero(date.getMonth() + 1) + "/" + date.getFullYear();
}

function addZero(number) {
    if(number < 10) {
        return "0" + number;
    }
    return number;
}

function addDays(date, days) {
    var temp = date;
    temp.setDate(date.getDate() + days);
    return temp;
}

function addMonths(date, months) {
    var temp = date;
    temp.setMonth(date.getMonth() + months);
    return temp;
}

function formatClocktime(date1, date2) {
    return addZero(date1.getHours()) + ":" + addZero(date1.getMinutes()) + " - " + addZero(date2.getHours()) + ":" + addZero(date2.getMinutes()) + (date1.getDate() != date2.getDate() ? " (+ 1Tag)" : "");
}

export {formatClocktime, addMonths, formatDate, addDays, getTimeFromDate, getDateFromDate, getDateFromDateAndTimeString, getDateFromDateAndTimeStringAddDays, getDateFromNormalDateAndTimeString, getDateFromNormalDateAndTimeStringAddDays}