import { openPopUp } from "../components/popUp";

const API_URL = "https://api.bergamlaimer.info/";

function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }  
    return true;
  }

function sendRequest(path, verb, body, onresp, auth) {

    fetch(API_URL + path, {
        method: verb,
        body: Object.keys(body).length ? JSON.stringify(body) : undefined,
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Authorization': auth,
            //TODO: Replace with real ip in apache
            "X-Real-IP": "f"
        },
    })
        .then((response) => response.json())
        .then((data) => {
            onresp(data);
        })
        .catch((err) => {
            console.log("An error occured while api request: " + err);
            openPopUp("Die API ist offline");
            //window.open("https://bergamlaimer.info/error/500", "_self");
        });
}

function getApiUrl() {
    return API_URL;
}

function formatDate(d) {
    var dateObj = new Date(d);
    return addZeroToNumberUnderTen(dateObj.getDate()) + "/" + addZeroToNumberUnderTen(dateObj.getMonth()+1) + "/" + dateObj.getFullYear();
}


function addZeroToNumberUnderTen(number) {
    if(number < 10) {
        return "0" + number;
    }
    return number;
}

export {sendRequest, getApiUrl, formatDate}