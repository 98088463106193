import React, { useState } from "react";

import IconButton from "../../components/iconButton";
import ActivateIcon from "../../assets/icons/activate";
import DeactivateIcon from "../../assets/icons/deactivate";
import TextField from "../../components/textField";
import { submitNewsletterChange } from "../dashboardSendNewsletter";
import Button from "../../components/button";
import { sendTestNewsletter } from "../../content/newsletterManager";
import { openPopUp } from "../../components/popUp";
import { formatDate } from "../../content/apiManager";


export default function NewsletterGeneral(props) {

    const [testMail, setTestMail] = useState("");

    var today = new Date();
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);

    var distance = lastDayOfMonth - today;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return <div className="newsletter-general">
        <div className="disable">
            <IconButton onClick={() => {
            submitNewsletterChange("enabled", !props.data.enabled);
                    
                }}>{!props.data.enabled ? <ActivateIcon/> : <DeactivateIcon/>}</IconButton>
                <p>Newsletter {!props.data.enabled ? "aktivieren" : "deaktivieren"}</p>
        </div>
        {props.data.enabled ? <p className="info">Nächster Newsletter wird am {formatDate(lastDayOfMonth)} (in {days + "d " + hours + "h "
  + minutes + "m"}) versendet.</p> : <></>}
        <p className="info">Momentan sind <span>{props.data.email_count}</span> Emails im Newsletter registriert.</p>

        <TextField onChange={setTestMail} placeholder="Test Email" label="Test Email senden"/>
        <Button onClick={() => {
            sendTestNewsletter(testMail, () => {
                openPopUp("Test Mail erfolgreich gesendet");
            });
        }}>senden</Button>

        <TextField onChange={(v) => {
            submitNewsletterChange("title", v);
        }} defaultValue={props.data.title} placeholder="Titel eingeben" label="Titel"/>
        <TextField onChange={(v) => {
            submitNewsletterChange("subtitle", v);
        }} defaultValue={props.data.subtitle} placeholder="Untertitel eingeben" label="Untertitel"/>
        <TextField onChange={(v) => {
            submitNewsletterChange("text", v);
        }} defaultValue={props.data.text} autogrow textarea placeholder="Begrüßungstext eingeben" label="Begrüßungstext"/>
        <TextField onChange={(v) => {
            submitNewsletterChange("upcoming", v);
        }} defaultValue={props.data.upcoming} autogrow textarea placeholder="Abschiedstext eingeben" label="Abschiedstext"/>
        <TextField onChange={(v) => {
            submitNewsletterChange("footer_title", v);
        }} defaultValue={props.data.footer_title} placeholder="Footer Titel eingeben" label="Footer Titel"/>
        <TextField onChange={(v) => {
            submitNewsletterChange("footer_text", v);
        }} defaultValue={props.data.footer_text} autogrow textarea placeholder="Footer Text eingeben" label="Footer Text"/>
    </div>;
}