import React, { useEffect, useRef, useState } from "react";
import {useParams, useNavigate} from "react-router-dom";
import CreateDashboardPage from "../components/createDashboardPage";
import TextField from "../components/textField";
import Button from "../components/button";
import ButtonForward from "../components/buttonForward";
import { openActionPage } from "../components/actionPage";
import LinkEventAction from "./action/linkEvent";
import IconButton from "../components/iconButton";
import DeleteIcon from "../assets/icons/delete";
import { openPopUp } from "../components/popUp";
import { getDateFromDate, getDateFromDateAndTimeString, getTimeFromDate } from "../utils/dateUtils";
import { addHelperEntry, deleteHelperEntry, getHelperCategories, getHelperEntry, updateHelperEntry } from "../content/helperManager";
import MultipleSelect from "../components/multipleSelect";
import { getAccessableEvents } from "../content/eventManager";
import Checkbox from "../components/checkbox";

export default function DashboardHelperCreate(props) {

    const navigate = useNavigate();

    const {id} = useParams();
    const firstExecute = useRef(false);
    const [data, setData] = useState({});

    const [always, setAlways] = useState();
    const [date, setDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [title, setTitle] = useState();
    const [location, setLocation] = useState();
    const [description, setDescription] = useState();
    const [mail, setMail] = useState();
    const [phone, setPhone] = useState();
    const [linkedEvent, setLinkedEvent] = useState(-1);
    const [eventTitle, setEventTitle] = useState("");
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState();

    const [eventCount, setEventCount] = useState(0);

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if(firstExecute.current) return;
        if(id) {
            setEdit(true);
            getHelperEntry(id, (data, category) => {
                var newData = data;
                newData.category = category;
                newData.date = getDateFromDate(new Date(data.startTime));
                newData.starttime = getTimeFromDate(new Date(data.startTime));
                newData.endtime = getTimeFromDate(new Date(data.endTime));
                if(data.event) {
                    setEventTitle(data.event.title);
                    setLinkedEvent(data.event.id);
                }
                setData(data);
                setAlways(data.always);
            });
        }
        getHelperCategories(setCategories);
        getAccessableEvents((r) => {
            setEventCount(r.length);
        });
        firstExecute.current = true;
    });

    var clicked = false;

    return <CreateDashboardPage onClick={() => {
        if(clicked) return;
        var tempDate = date;
        var tempStartTime = startTime;
        var tempEndTime = endTime;
        clicked = true;
        if(always) {
            tempDate = "2000-01-01";
            tempStartTime = "00-00";
            tempEndTime = "00-00";

        }
        if(!title || !description || category == undefined || !tempDate || !tempStartTime || !tempEndTime) {
            if(!edit) {
                openPopUp("Titel, Beschreibung, Kategorie und die Uhrzeiten dürfen nicht unausgefüllt sein");
                return;
            }
        }
        var newDate = tempDate, newStartTime = tempStartTime, newEndTime = tempEndTime;
        if(tempDate != undefined || tempStartTime != undefined || tempEndTime != undefined) {
            if(tempDate == undefined) {
                newDate = getDateFromDate(new Date(data.startTime));
            }
            if(startTime == undefined) {
                newStartTime = getTimeFromDate(new Date(data.startTime));
            }
            if(endTime == undefined) {
                newEndTime = getTimeFromDate(new Date(data.endTime));
            }
        }
        const start = newDate && newStartTime ? getDateFromDateAndTimeString(newDate, newStartTime).getTime() / 1000 : undefined, end = newDate && newEndTime ? getDateFromDateAndTimeString(newDate, newEndTime).getTime() / 1000 : undefined;
        if(edit) {
            updateHelperEntry(id, title, description, category, start, end, location, mail, phone, linkedEvent, always, () => {
                navigate("/aider");
            });
            return;
        }
        addHelperEntry(title, description, category, start, end, location, mail, phone, linkedEvent, always, () => {
            navigate("/aider");
        });

        
    }} id="create-aider" back="aider" title={"Helfergesuch " + (edit ? "bearbeiten" : "erstellen")} finishButton="veröffentlichen">
        <Checkbox className="standalone" clickable defaultValue={data.always} onChange={setAlways} label="ab sofort"/>
        {!always ? <div className="input-flex">
            <TextField defaultValue={data.date} date label="Datum" onChange={setDate}/>
            <TextField defaultValue={data.starttime} time label="Uhrzeit Beginn" onChange={(v) => {setStartTime(v);console.log(v)}}/>
            <TextField defaultValue={data.endtime} time label="Uhrzeit Ende" onChange={(v) => {setEndTime(v)}}/>
        </div> : <></>}
        <ButtonForward disabled={eventCount==0} onClick={() => {
            openActionPage(<LinkEventAction defaultValue={linkedEvent} onClick={(v, t) => {
                setLinkedEvent(v);
                setEventTitle(t);
            }}/>);
        }} title="mit Termin verknüpfen"/>
        {linkedEvent >= 0 ? 
        <p className="linked">Verknüpft mit: {eventTitle}</p> : <></>}
        <TextField defaultValue={data.title} label="Titel" placeholder="Titel eingeben" onChange={setTitle}/>
        <TextField defaultValue={data.description} textarea autogrow label="Beschreibung" placeholder="Beschreibe was zu tun ist" onChange={setDescription}/>
        <MultipleSelect label="Kategorie" defaultValue={data.category} onChange={setCategory} items={categories}/>
        <TextField defaultValue={data.location} label="Ort" placeholder="Wo ist Hilfe gefragt?" onChange={setLocation}/>
        <TextField defaultValue={data.mail} label="Kontakt: Email" placeholder="Wenn vorhanden Email angeben" onChange={setMail}/>
        <TextField defaultValue={data.phone} label="Kontakt: Telefon" placeholder="Wenn vorhanden Telefonnummer angeben" onChange={setPhone}/>

        {edit ?<div className="delete-icon">
            <IconButton onClick={() => {
                deleteHelperEntry(id, () => {
                    navigate("/aider");
                });
            }}><DeleteIcon/></IconButton>
            <p>Löschen</p>
        </div> : <></>}
    </CreateDashboardPage>;
}