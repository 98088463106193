import React from "react";

export default function DisabledEyeIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="26.359" height="19.328" viewBox="0 0 26.359 19.328">
    <g id="Gruppe_9" data-name="Gruppe 9" transform="translate(-695.14 -519.336)">
      <path id="Pfad_1" data-name="Pfad 1" d="M608,3295.87s5.9-7.87,11.8-7.87,11.8,7.87,11.8,7.87-5.9,7.87-11.8,7.87S608,3295.87,608,3295.87Z" transform="translate(88.39 -2766.74)" fill="none" stroke="#000" stroke-width="2"/>
      <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(705.39 526)" stroke="#fff" stroke-width="2">
        <circle cx="3" cy="3" r="3" stroke="none"/>
        <circle cx="3" cy="3" r="4" fill="none"/>
      </g>
      <line id="Linie_8" data-name="Linie 8" y1="16" x2="24" transform="translate(696.39 521)" fill="none" stroke="#fff" stroke-width="4"/>
      <line id="Linie_7" data-name="Linie 7" y1="16" x2="24" transform="translate(696.39 521)" fill="none" stroke="#000" stroke-width="2"/>
    </g>
  </svg>;
}