import EditImageBox from "./editImageBox";

import "../style/clubListBox.css";
import { useNavigate } from "react-router-dom";

export default function ClubListBox(props) {

    const navigate = useNavigate();

    return <div className={"club-list-box" + (props.hidden ? " hidden" : "")} onClick={() => {
        navigate("/club/" + props.id + "/general");
    }}>
        <h1>{props.name}</h1>
        <EditImageBox notclickable link={props.image}/>
        <h2>{props.owner}</h2>
    </div>;
}