import React, { useState } from "react";
import EditImageBox from "./editImageBox";

import {useNavigate} from "react-router-dom";

import "../style/eventListElement.css";
import PenIcon from "../assets/icons/pen";
import DeleteIcon from "../assets/icons/delete";
import CopyIcon from "../assets/icons/copy";
import { openActionPage } from "./actionPage";
import DeleteEventAction from "../pages/action/deleteEvent";
import { getCategory } from "../content/loginManager";
import Checkbox from "./checkbox";

export default function ChooseListElement(props) {

    const [value, setValue] = useState(props.defaultValue ? props.defaultValue : false);

    return <div className={"event-list-element choose-list-element" + (!value ? " disabled" : "")} onClick={() => {
        props.onChange(!value);
        setValue(!value);
    }}>
        <div className="info">

            <Checkbox onChange={() => {}} defaultValue={value}/>
            
            <EditImageBox link={props.image} notclickable/>
            <p>{props.date}</p>
            <p className="title">{props.title}<p>{getCategory() == "SUPERADMIN" || getCategory() == "ADMIN" ? props.user : ""}</p></p>
        </div>
    </div>

}