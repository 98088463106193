import { useState } from "react";
import ActionPage, { closeActionPage } from "../../components/actionPage";
import Button from "../../components/button";
import TextField from "../../components/textField";
import { addClubCategory } from "../../content/clubManager";

export default function AddClubCategoryAction(props) {

    const [category, setCategory] = useState("");

    return <ActionPage>
        <TextField onChange={setCategory} label="Neue Kategorie" placeholder="Kategorienamen eingeben"/>
        <Button onClick={() => {
            addClubCategory(category, () => {
                closeActionPage();
                document.dispatchEvent(new CustomEvent("addClubCategory", {
                    detail: {
                        "name": category
                    }
                }));
            });
        }}>Hinzufügen</Button>
    </ActionPage>
}