import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Checkbox from "../../components/checkbox";
import CreateDashboardPage from "../../components/createDashboardPage";
import MultipleSelect from "../../components/multipleSelect";
import Button from "../../components/button";

import "../../style/createEvent.css";
import DashboardCalendar from "../../components/dashboardCalendar";
import TextField from "../../components/textField";
import { openPopUp } from "../../components/popUp";
import { addDays, addMonths, getDateFromDate } from "../../utils/dateUtils";

export default function DashboardCreateEventDate(props) {

    const firstExecute = useRef(false);    

    const repeatFrequencyEl = useRef();
    const [date, setDate] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [repeat, setRepeat] = useState(false);
    const [repeatDates, setRepeatDates] = useState([]);
    const [repeatFrequency, setRepeatFrequency] = useState(0);
    const [repeatType, setRepeatType] = useState(-1);
    const [defaultDates, setDefaultDates] = useState([]);

    var repeatable = (date != undefined && startTime != undefined && endTime != undefined) || (props.data.date != undefined && props.data.starttime != undefined && props.data.endtime != undefined);

    useEffect(() => {
        if(props.data.time) {
            var timeKeys = Object.keys(props.data.time);
    
            for(var i = 0; i < timeKeys.length; i++) {
                defaultDates.push(new Date(timeKeys[i]));
            }
            setDefaultDates(defaultDates);
            var temp = timeKeys.length > 1;
            if(!firstExecute.current) {
                setRepeat(temp);
                firstExecute.current = true;
            }
        }
    });

    return <div id="create-date">
        <div className="time input-flex">
            <TextField maxValue={"2200-01-01"} defaultValue={props.data.date} date label="Datum" onChange={(v) => {props.submit("date", v);setDate(v)}}/>
            <TextField defaultValue={props.data.starttime} time label="Uhrzeit Beginn" onChange={(v) => {props.submit("starttime", v);setStartTime(v)}}/>
            <TextField defaultValue={props.data.endtime} time label="Uhrzeit Ende" onChange={(v) => {props.submit("endtime", v);setEndTime(v)}}/>
        </div>
        <div className="repeat">
            <div className="left">
                <Checkbox defaultValue={repeat} clickable={repeatable} label="wiederholen" onChange={(v) => {
                    if(!repeatable) {
                        openPopUp("Wähle zunächst Datum und Zeit");
                        return;
                    }
                    setRepeat(v);
                    props.submit("repeat", []);
                }}/>
                {repeat ? <><MultipleSelect onChange={setRepeatType} items={[
                    "wöchentlich",
                    "14-tägig",
                    "monatlich"
                ]}/>
                <div className="frequency">
                    <input ref={repeatFrequencyEl} defaultValue={repeatFrequency} type="number" pattern="^[0-9]*$" onKeyDown={(evt) => {
                        evt = (evt) ? evt : window.event;
                        var charCode = (evt.which) ? evt.which : evt.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                            evt.preventDefault();
                        }
                        if(evt.target.value > 10 && !(charCode < 48 || charCode > 57)) {
                            evt.preventDefault();
                        }
                        return true;
                    }} onChange={(e) => {setRepeatFrequency(e.target.value)}}/>
                    <a>mal</a>
                </div>
                <Button disabled={repeatType == -1 || !repeatFrequency || repeatFrequency <= 0} thin onClick={() => {
                    var dates = [];
                    var repeatDate = props.data.date ? props.data.date : date;
                    var inc = repeatType == 0 ? 7 : repeatType == 1 ? 14 : 30;
                    for(var i = 1; i <= repeatFrequency; i++) {
                        if(inc == 30) {
                            dates.push(addMonths(new Date(repeatDate), i));
                        }else {
                            dates.push(addDays(new Date(repeatDate), inc*i));
                        }
                    }
                    document.dispatchEvent(new CustomEvent("addDatesDashboardCalendar", {
                        detail: {
                            "dates": dates
                            }
                    }));
                    setRepeatFrequency(0);
                    repeatFrequencyEl.current.value = 0;
                }}>anwenden</Button>
                <p className="info">Alternativ Daten im Kalender anklicken</p></> : <></>}
            </div>
            {repeat && firstExecute ? 
            <DashboardCalendar defaultValue={defaultDates} startDate={new Date(date || props.data.date)} onChange={(v) => {
                props.submit("repeat", v);
            }}/> : <></>}
        </div>
    </div>;
}