import React from "react";
import SaveButton from "../components/saveButton";
import Page from "../components/page";
import SearchHeader from "../components/searchHeader";

export default function DashboardSimpleContent(props) {
    return <Page id="content">
        <div className="main-box">
            <SearchHeader setSearch={props.updateSearch} button={<></>}/>
            <div className="content">
                {props.children}
            </div>
            <SaveButton onClick={props.onClick} changeMade={props.changeMade}/>
        </div>
    </Page>
}