import { openPopUp } from "../components/popUp";
import { sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

function getAccessableFutureEvents(onFinish) {
    sendRequest("event/accessable/future", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.events);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function deleteEvent(id, onFinish) {
    sendRequest("event/" + id, "DELETE", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.events);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function getAccessableEvents(onFinish) {
    sendRequest("event/accessable", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.events);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function updateEvent(id, title, description, imageLink, imageAttributes, location, category, times, onFinish) {
    sendRequest("event/" + id, "PATCH", {
        "title": title,
        "description": description,
        "imageLink": imageLink,
        "imageAttribute": imageAttributes,
        "location": location,
        "category": category,
        "times": times
    }, (r) => {
        if(r.status == 200) {
            onFinish(r);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function createEvent(title, description, imageLink, imageAttributes, location, category, times, onFinish) {
    sendRequest("event", "POST", {
        "title": title,
        "description": description,
        "imageLink": imageLink,
        "imageAttribute": imageAttributes,
        "location": location,
        "category": category,
        "times": times
    }, (r) => {
        if(r.status == 200) {
            onFinish(r);
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function getOneEvent(id, onFinish) {
    sendRequest("event/" + id, "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data);
        }else {
            openPopUp(r.message);
        }
    });
}


export {updateEvent, getOneEvent, deleteEvent, createEvent, getAccessableFutureEvents, getAccessableEvents};