export default function UploadIcon() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="31.92" height="28.614" viewBox="0 0 31.92 28.614">
    <g id="Gruppe_95" data-name="Gruppe 95" transform="translate(-651.539 -605.586)">
      <path id="Pfad_14" data-name="Pfad 14" d="M-1.2,1-5.61,5.474H21.53L17.118,1H-1.2m-.418-1H17.536L23.92,6.474H-8Z" transform="translate(659.539 627.726)"/>
      <g id="Gruppe_53" data-name="Gruppe 53" transform="translate(-39 -39.3)">
        <line id="Linie_17" data-name="Linie 17" y2="13" transform="translate(706.5 647.5)" fill="none" stroke="#000" stroke-width="3"/>
        <path id="Pfad_11" data-name="Pfad 11" d="M10670,4916.691l5.691-5.691,5.691,5.691" transform="translate(-9969.191 -4264.7)" fill="none" stroke="#000" stroke-width="2"/>
      </g>
      <path id="Pfad_18" data-name="Pfad 18" d="M16585,7308.7h-6.953v10.987h18.984V7308.7H16590" transform="translate(-15920 -6692)" fill="none" stroke="#000" stroke-width="1"/>
    </g>
  </svg>;
}