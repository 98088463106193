import { useRef, useState } from "react";
import ActionPage, { closeActionPage } from "../../components/actionPage";
import IconButton from "../../components/iconButton";
import UploadIcon from "../../assets/icons/upload";
import { uploadImage } from "../../content/fileManager";
import TextField from "../../components/textField";
import Switch from "../../components/switch";

export default function AddImageAction(props) {

    const [loading, setLoading] = useState(false);
    const executed = useRef(false);

    function finish(file) {
        if(loading) return;
        setLoading(true);
        uploadImage(file, (r) => {
            if(executed.current) return;
            executed.current = true;
            props.onFinish(r);
            closeActionPage();
        });
    }

    return <ActionPage loading={loading} onCancel={props.onCancel} draganddrop onDrop={finish}>
    <IconButton onClick={() => {
        var input = document.createElement('input');
        input.type = 'file';
        input.addEventListener("change", ((e) => {
            var file = e.target.files[0];
            finish(file);
        }));
        input.click();
    }}><UploadIcon/></IconButton>
    <p>Bild auswählen oder per Drag and Drop auf dieses Fenster schieben.</p>

    </ActionPage>;
}