import React, { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import Dashboard from "./dashboard";
import Page from "../components/page";
import Button from "../components/button";
import ArrowBigIcon from "../assets/icons/arrowBig";
import TextField from "../components/textField";
import "../style/accountInfo.css";
import DeleteIcon from "../assets/icons/delete";
import PasswordIcon from "../assets/icons/password";
import DeactivateIcon from "../assets/icons/deactivate";
import ActivateIcon from "../assets/icons/activate";
import IconButton from "../components/iconButton";
import { activateAccount, deactivateAccount, getAccountInfo, getAccountTypes, isDeleteable } from "../content/accountManager";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/loader"
import { formatDate } from "../content/pageManager";
import { openActionPage } from "../components/actionPage";
import DeleteAccountAction from "./action/deleteAccount";
import NotDeleteableAction from "./action/notDeleteable";
import ChangePasswordAction from "./action/changePassword";
import { getClubCategories, hasClub, registerClubWUser } from "../content/clubManager";
import ButtonBack from "../components/buttonBack";
import ButtonForward from "../components/buttonForward";
import SingleAddIcon from "../assets/icons/singleAdd";

function DashboardAccountInfo() {  
    const navigate = useNavigate(); 

    const {username} = useParams();

    const [data, setData] = useState();
    const [clubPage, setClubPage] = useState(-1);

    useEffect(() => {
        if(data == undefined) { 
            getAccountInfo(username, setData);
            hasClub(username, setClubPage);
        }
    });

    function reload() {
        setData(undefined);
    }

    if(data == undefined) {
        return <Loader/>;
    }

    return <Page id="account-info">
        <div className="main-box">
            <div className="header">
                <ButtonBack onClick={() => {
                    navigate("/accounts");
                }}/>
            </div>
            <div className="content">
                <h1 className={data.enabled ? "" : "deactivated"}>{data.username}</h1>
                <h3>{getAccountTypes()[data.category]}</h3>
                {clubPage != -1 ? <ButtonForward title="Vereinsseite anzeigen" onClick={() => {
                    navigate("/club/" + clubPage + "/general");
                }}/> : data.category == "CLUB" ? <Button thin className="button-forward invert" onClick={() => {
                    getClubCategories((categories) => {
                        registerClubWUser(categories[0], data.id, (r) => {
                    navigate("/club/" + r.id + "/general");
                        })
                    });
                }}>Vereinsseite erstellen<SingleAddIcon/></Button> : <div className="placeholder"/>}
            </div>
            <div className="bottom">
                <div className="buttons">
                    <IconButton onClick={() => {
                        openActionPage(<ChangePasswordAction username={username}/>);
                    }}><PasswordIcon/></IconButton>
                    <p>Passwort ändern</p>
                    {data.enabled ? 
                        <><IconButton onClick={() => {
                            deactivateAccount(username, reload);
                        }}><DeactivateIcon/></IconButton>
                        <p>Deaktivieren</p></>
                        :
                        <><IconButton onClick={() => {
                            activateAccount(username, reload);
                        }}><ActivateIcon/></IconButton>
                        <p>Aktivieren</p></>
                    }
                    <IconButton onClick={() => {
                        isDeleteable(username, () => {
                            openActionPage(<DeleteAccountAction username={username}/>);
                        }, () => {
                            openActionPage(<NotDeleteableAction/>);
                        })
                    }}><DeleteIcon/></IconButton>
                    <p>Löschen</p>
                </div>
                <div className="info">
                    <p className="label">Account erstellt am</p>
                    <p>{data.createdAt == null || new Date(data.createdAt).getTime() == 0 ? "noch nie" : formatDate(data.createdAt)}</p>
                    <p className="label">Letzter Zugriff am</p>
                    <p>{data.lastSeen == null || new Date(data.lastSeen).getTime() == 0 ? "noch nie" : formatDate(data.lastSeen)}</p>
                    <p className="label">Erstellte Beiträge & Termine</p>
                    <p>{data.contributions}</p>
                </div>
            </div>
        </div>
    </Page>;

}

export default DashboardAccountInfo;