import { openPopUp } from "../components/popUp";
import { getApiUrl, sendRequest } from "./apiManager";
import { getSessionId } from "./loginManager";

var validExtensions = ["jpg", "png", "jpeg", "webp", "svg", "tif"]

function getStaticImages(onFinish) {
    sendRequest("image/static", "GET", {}, (r) => {
        onFinish(r.data.ids);
    });
}

function getFileExtension(filename) {
    return filename.toLowerCase().split('.').pop();
}

function uploadStaticImage(uuid, file, onFinish) {
    if(!validExtensions.includes(getFileExtension(file.name))) {
        openPopUp("Ungültiger Dateityp");
        return;
    }
    if(file.size > 210000000) {
        openPopUp("Dein Bild darf maximal 2MB groß sein");
        return;
    }
    const formData = new FormData();
    formData.append("file", file);
    fetch(getApiUrl() + "image/static/" + uuid, {
        method: "PATCH",
        body: formData,
        headers: {
            'Authorization': getSessionId(),
            //TODO: Replace with real ip in apache
            "X-Real-IP": "f"
        },
    })
        .then((response) => response.json())
        .then((r) => {
            if(r.status == 200) {
                onFinish(r.data.imageLink);
            }else {
                openPopUp(r.message);
            }
        })
        .catch((err) => {
            console.log("An error occured while api request: " + err);
            //TODO: Error handle
        });
}

function uploadImage(file, onFinish) {
    if(!validExtensions.includes(getFileExtension(file.name))) {
        openPopUp("Ungültiger Dateityp");
        return;
    }
    if(file.size > 210000000) {
        openPopUp("Dein Bild darf maximal 2MB groß sein");
        return;
    }
    const formData = new FormData();
    formData.append("file", file);
    fetch(getApiUrl() + "image/upload", {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': getSessionId(),
            //TODO: Replace with real ip in apache
            "X-Real-IP": "f"
        },
    })
        .then((response) => response.json())
        .then((r) => {
            if(r.status == 200) {
                onFinish(r.data.imageLink);
            }else {
                openPopUp(r.message);
            }
        })
        .catch((err) => {
            console.log("An error occured while api request: " + err);
            //TODO: Error handle
        });
}

export {uploadStaticImage, uploadImage, getStaticImages}