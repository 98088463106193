import React, {useEffect, useRef, useState} from "react";

import "../style/multipleSelect.css";

import ChevronIcon from "../assets/icons/chevron";
import DeleteIcon from "../assets/icons/delete";
import { deleteClubCategory } from "../content/clubManager";
import { openPopUp } from "./popUp";
import { openActionPage } from "./actionPage";
import AddClubCategoryAction from "../pages/action/addClubCategory";
import { click } from "@testing-library/user-event/dist/click";

function MultipleSelect(props) {

    const dropdownEl = useRef();
    const [selected, setSelected] = useState(props.defaultValue ? props.defaultValue : -1);
    const [active, setActive] = useState(false);
    const [defaultItems, setDefaultItems] = useState(props.items);
    const [items, setItems] = useState(props.items);
    const [defaultValue, setDefaultValue] = useState(props.defaultValue);

    useEffect(() => {
        if(defaultItems != props.items) {
            setItems(props.items);
            setDefaultItems(props.items);
        }
        if(defaultValue != props.defaultValue) {
            setDefaultValue(props.defaultValue);
        }
    })

    var added = false;

    useEffect(() => {
        if(defaultValue != undefined && selected == -1) {
            setSelected(defaultValue);
            setItems(props.items);
        }
        document.addEventListener("addClubCategory", (e) => {
            if(added) return;
            var temp = items;
            if(!items.includes(e.detail.name)) {
                temp.push(e.detail.name);
            }
            setItems(temp);
            added = true;
        });
        dropdownEl.current.scrollTo(0, 0);
    });

    var clickDelete = false;

    function closeDropdown() {
        setActive(false);
    }

    return <div className={"multiple-select" + (active ? " active" : "")}>
        <p>{props.label}</p>
            <div ref={dropdownEl} className="dropdown" onClick={() => {
                if(clickDelete) return;
                if(active) {
                    closeDropdown();
                }else {
                    setActive(true);
                }
        }}>
            <ChevronIcon className="chevron-down"/> {(selected != -1 ? <p className="active">{props.items[selected]}</p> : active ? <></> : <p className="active empty">Klicke hier</p>)}
            {items.map((e, i) => {
                if(i == selected) return;
                return <p onClick={() => {
                    if(clickDelete) return;
                    setSelected(i);
                    if(props.onChange) {
                        props.onChange(i);
                    }
                }}>{e}{props.editable ? <div className="delete" onClick={() => {
        clickDelete = true;
        deleteClubCategory(e, () => {
            openPopUp("Kategorie erfolgreich gelöscht");
            var temp = [];
            for(var i = 0; i < items.length; i++) {
                if(items[i] == e) continue;
                temp.push(items[i]);
            }
            setItems(temp);
        });
    }}><DeleteIcon/></div> : <></>}</p>;
            })}
            {props.editable ? <p className="add" onClick={props.onAdd}>{props.add}</p> : <></>}

            
        </div>
        <div className="overlay" onClick={() => {
            closeDropdown();
        }}/>
    </div>;
}

export default MultipleSelect;