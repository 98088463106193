import React from "react";
import ContentBase from "../contentbase/contentBase";
import CreateArticleContent from "./createArticleContent";
import CreateArticleGeneral from "./createArticleGeneral";
import { convertTagListToString } from "../../components/tagList";
import { castArticleParagraph, convertToArticleParagraphs } from "../../content/articleParagraph";
import { addArticle } from "../../content/articleManager";
import { useNavigate } from "react-router-dom";
import { openPopUp } from "../../components/popUp";

import "../../style/createArticle.css";

export default function CreateArticle(props) {

    const navigate = useNavigate();

    var attributes = {};
    for(var i = 0; i < Object.keys(props).length; i++) {
        attributes[Object.keys(props)[i]] = props[Object.keys(props)[i]];
    }
    attributes.data = props.data ? props.data : {}
    attributes.back = "articles";
    attributes.title = "Neuen Beitrag erstellen";
    attributes.pages = [
        {
            name: "general",
            button: "Inhalt erstellen",
            children: <CreateArticleGeneral/>
        },
        {
            name: "content",
            button: "Beitrag veröffentlichen",
            children: <CreateArticleContent/>
        }
    ];
    attributes.onFinish = (data) => {
        if(!data.title || !data.subtitle) {
            openPopUp("Titel und Untertitel dürfen nicht leer sein");
            return;
        }
        if(!data.sections || data.sections.length == 0) {
            openPopUp("Der Beitrag darf nicht leer sein");
            return;
        }
        var tagList = convertTagListToString(data.tags);
        var sections = convertToArticleParagraphs(data.sections);
        addArticle(data.title, data.subtitle, data.author, tagList, sections, () => {
            navigate("/articles");
        })
    };

    return React.cloneElement(<ContentBase/>, attributes);
}