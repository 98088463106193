import ImageLink from "../components/imageLink";
import ClubImage from "./clubImage";

const formatOptions = {
    "text-only": [true, 0],
    "img-left": [true, 1],
    "img-right": [true, 1],
    "text-center": [true, 2],
    "three-img": [false, 3],
    "fs-img": [false, 1]
}

class ArticleParagraph {
    constructor(formatOption, text, images) {
        this.formatOption = formatOption;
        this.text = text;
        this.images = images;
    }
    getJson() {
        var json = {};
        json.text = this.text;
        json.attributes = this.getAttributes();
        var images = "";
        var imageAttributes = "";
        for(var i = 0; i < this.images.length; i++) {
            var el = this.images[i];
            images += el.getLink() + ";";
            imageAttributes += el.getAttributes()[0] + "," + el.getAttributes()[1] + ";";
        }

        if(images.length > 0) {
            images = images.substring(0, images.length - 1);
            imageAttributes = imageAttributes.substring(0, imageAttributes.length - 1);
        }
        json.imageLinks = images;
        json.imageAttributes = imageAttributes;
        return json;
    }
    getAttributes() {
        var json = {};
        if(this.formatOption == 1) {
            json.alignImages = "left";
        }else if(this.formatOption == 2) {
            json.alignImages = "right";
        }
        return json;
    }
    getFormatOption() {
        return this.formatOption;
    }
    getText() {
        return this.text;
    }
    setImages(images) {
        this.images = images;
    }
    getImages() {
        return this.images;
    }
    isValid() {
        var formatKeys = Object.keys(formatOptions);
        for(var i = 0; i < formatKeys.length; i++) {
            if(i != this.formatOption) continue;
            var el = formatOptions[formatKeys[i]];
            return el[0] == this.text.length > 0 && el[1] == this.images.length;
        }
        return false;
    }
}

function castArticleParagraph(obj) {
    var e = new ArticleParagraph(-1, "", []);
    Object.assign(e, obj);
    var imgs = e.getImages();
    var realImgs = [];
    for(var i = 0; i < imgs.length; i++) {
        var newImg = new ClubImage();
        Object.assign(newImg, imgs[i]);
        realImgs.push(newImg);
    }
    e.setImages(realImgs);
    return e;
}

function loadArticleParagraph(obj) {
    var formatOption = -1;
    if(obj.content.length == 0) {
        if(obj.images.length == 1) {
            formatOption = 5;
        }else if(obj.images.length == 3) {
            formatOption = 4;
        }
    }else if(obj.images.length == 1) {
        if(obj.attributes.alignImages == "right") {
            formatOption = 2;
        }else {
            formatOption = 1;
        }
    }else if(obj.images.length == 2) {
        formatOption = 3;
    }else {
        formatOption = 0;
    }
    var images = [];
    for(var i = 0; i < obj.images.length; i++) {
        var el = obj.images[i];
        var a = obj.imageAttributes.split(";")[i].split(",");
        images.push(new ClubImage(el.account + "/" + el.uuid, a[0] == "true", a[1]));
    }
    return new ArticleParagraph(formatOption, obj.content, images);
}

function convertToArticleParagraphs(input) {
    var sections = [];
    for(var i = 0; i < input.length; i++) {
        var el = castArticleParagraph(input[i]);
        sections.push(el.getJson());
    }
    return sections;
}

export {ArticleParagraph, convertToArticleParagraphs, castArticleParagraph, loadArticleParagraph}