import { useState } from "react";
import ActionPage from "../../components/actionPage";
import UploadIcon from "../../assets/icons/upload";
import IconButton from "../../components/iconButton";
import { closeActionPage } from "../../components/actionPage";
import { uploadStaticImage } from "../../content/fileManager";


export default function EditStaticImageAction(props) {

    
    const [loading, setLoading] = useState(false);

    function finish(file) {
        setLoading(true);
        uploadStaticImage(props.uuid, file, (r) => {
            props.changeLogo(r);
            closeActionPage();
            setLoading(false);
        });
    }

    return <ActionPage loading={loading} draganddrop onDrop={finish}>
        <IconButton onClick={() => {
            var input = document.createElement('input');
            input.type = 'file';
            input.addEventListener("change", ((e) => {
                var file = e.target.files[0];
                finish(file);
            }));
            input.click();
        }}><UploadIcon/></IconButton>
        <p>Neues Bild auswählen oder per Drag and Drop auf dieses Fenster schieben.</p>
    </ActionPage>
}