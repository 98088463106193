import React, {useEffect, useRef, useState} from "react";

import TextField from "../../components/textField";
import Switch from "../../components/switch";

import { submitClubChange } from "../dashboardClub";

import "../../style/sidebar.css";

function ClubSidebar(props) {

    const weekdayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];

    const firstExecute = useRef(false);
    const [openDisplay, setOpenDisplay] = useState(props.data.open.startsWith("days") ? 0 : 1);
    const [openValue, setOpenValue] = useState(props.data.open);
    const [dayArray, setDayArray] = useState([]);

    function isWeek(text) {
        if(text == undefined) return;
        return text.split("\n").length == 7 && weekdayNames.some(el => text.includes(el));
    }

    function updateOpen(value) {
        var output = "";
        output += openDisplay == 1 ? "text" : "days";
        if(openDisplay == 1) {
            output += "\n" + value;
        }else {
            for(var i = 0; i < weekdayNames.length; i++) {
                output += "\n" + weekdayNames[i] + " " + (value[i] ? value[i] : "");
            }
        }
        submitClubChange("open", output);
    }

    useEffect(() => {
        if(props.data.open.startsWith("days") && dayArray.length == 0) {
            var arr = [];
            for(var i = 1; i < props.data.open.split("\n").length; i++) {
                arr.push(props.data.open.split("\n")[i].split(" ")[1]);
            }
            setDayArray(arr);
        }
    })

    if(!props.data) {
        return <></>
    }

    const weekdays = props.data.open != undefined && props.data.open.includes("Montag") && props.data.open.includes("Sonntag") && props.data.open.includes("Mittwoch") && props.data.open.includes("Dienstag");
    if(!firstExecute.current) {
        setOpenDisplay(weekdays ? 0 : 1);
        firstExecute.current = true;
    }

    return <div className="sidebar">


        <TextField defaultValue={props.data.news} onChange={(v) => {
                submitClubChange("news", v);
            }} placeholder="Informationen eingeben" label="Aktuelle Information" textarea/>
        <TextField defaultValue={props.data.contact} onChange={(v) => {
            submitClubChange("contact", v);
        }} placeholder="info@example.com&#10;0049 111 11111 1111" label="Kontakt Information" textarea/>
        <TextField defaultValue={props.data.street} onChange={(v) => {
                submitClubChange("street", v);
            }} placeholder="Straße eingeben" label="Straße + Hausnr."/>
        <div className="text-field-div">
            
            <TextField defaultValue={props.data.zip} onChange={(v) => {
                submitClubChange("zip", v);
            }} placeholder="Postleitzahl eingeben" label="Postleitzahl"/>
            <TextField defaultValue={props.data.city} onChange={(v) => {
                submitClubChange("city", v);
            }} placeholder="Stadt angeben" label="Stadt"/>
        </div>
        <TextField defaultValue={props.data.addressAddition} onChange={(v) => {
                submitClubChange("addressAddition", v);
            }} placeholder="Adresszusatz eingeben" label="Adresszusatz"/>

        <div className="open">
            <div className="title">
                <p>Öffnungszeiten</p>
                <Switch onChange={setOpenDisplay} defaultValue={openDisplay} left="Woche" right="Freitext"/>
            </div>
            {
                openDisplay == 1 ? 
                <TextField defaultValue={props.data.open.startsWith("text") ? props.data.open.split("\n")[1] : ""} onChange={(v) => {
                    updateOpen(v);
                }} placeholder="Öffnungszeiten eingeben" textarea/>
                :
                <div className="weekdays">
                    {weekdayNames.map((e, i) => {
                        return <>
                            <h3>{e}</h3>
                            <input defaultValue={props.data.open.startsWith("days") ? props.data.open.split("\n")[i+1].split(" ")[1] : ""} placeholder="Uhrzeit eingeben" onChange={(event) => {
                                var arr = dayArray;
                                arr[i] = event.target.value;
                                updateOpen(arr);
                            }}/>
                        </>
                    })}
                </div>
            }
        </div>

    </div>;
}

export default ClubSidebar;