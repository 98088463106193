import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getArticleFormatJson } from "../../content/articleManager";
import CreateArticle from "./createArticle";

export default function CopyArticle(props) {

    const {id} = useParams();

    const [data, setData] = useState(undefined);
    const firstExecute = useRef(false);

    useEffect(() => {
        if(firstExecute.current) return;
        getArticleFormatJson(id, setData);
        firstExecute.current = true;
    })

    if(data == undefined) {
        return <></>;
    }

    return <CreateArticle general={props.general} content={props.content} data={data}/>;

}