import React, {useState} from "react";

import Page from "../components/page";
import TextField from "../components/textField";
import Button from "../components/button";
import { openPopUp } from "../components/popUp";
import { changeOwnPassword } from "../content/loginManager";
import { relogin } from "../content/loginManager";

function DashboardChangePassword() {

    const [old, setOld] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    return <Page className="small simple-page">
        <div className="main-box">
            <TextField password onChange={setOld} label="Bisheriges Passwort"/>
            <TextField password onChange={setNewPassword} label="Neues Passwort"/>
            <TextField password onChange={setRepeatPassword} label="Neues Passwort wiederholen"/>
            <Button onClick={() => {
                if(!old || !newPassword || !repeatPassword) {
                    openPopUp("Bitte fülle alle Felder aus");
                    return;
                }
                if(newPassword != repeatPassword) {
                    openPopUp("Die eingegebenen Passwörter stimmen nicht überein");
                    return;
                }
                relogin(old, () => {
                    changeOwnPassword(newPassword, () => {
                        openPopUp("Dein Passwort wurde erfolgreich geändert!");
                    })
                });
            }}>Passwort ändern</Button>
        </div>
    </Page>
}

export default DashboardChangePassword;