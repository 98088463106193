import { getSessionId } from "./loginManager";
import { sendRequest } from "./apiManager";
import { openPopUp } from "../components/popUp";

function changePassword(username, newPassword, onFinish) {
    sendRequest("account/changepassword", "POST", {
        "username": username,
        "newPassword": newPassword
    }, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function deactivateAccount(username, onFinish) {
    sendRequest("account/disable/" + username, "POST", {}, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function activateAccount(username, onFinish) {
    sendRequest("account/enable/" + username, "POST", {}, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function isDeleteable(username, onTrue, onFalse) {
    sendRequest("account/deleteable/" + username, "GET", {}, (r) => {
        if(r.status == 200) {
            if(r.data.deleteable) {
                onTrue();
            }else {
                onFalse();
            }
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function deleteAccount(username, onFinish) {
    sendRequest("account/" + username, "DELETE", {}, (r) => {
        if(r.status == 200) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

function getAccounts(onFinish) {
    sendRequest("account", "GET", {}, (r) => {
        if(r.status == 200) {
            onFinish(r.data.accounts);
        }else {
            //window.open("/error/500");
        }
    }, getSessionId());
}

function getAccountInfo(username, onFinish) {
    sendRequest("account/" + username, "GET", {}, (r) => {
            if(r.status == 200) {
                onFinish(r.data);
            }else {
                //window.open("/error/500");
            }
        }, getSessionId());
}

function getAccountTypes() {
    return {
        "ADMIN": "Admin",
        "EDITOR": "Editor",
        "CLUB": "Verein"
    }
}

function createAccount(username, password, category, email, onFinish) {
    sendRequest("account/create", "POST", {
        "username": username,
        "password": password,
        "category": category,
        "email": email
    }, (r) => {
        if(r.status == 201) {
            onFinish();
        }else {
            openPopUp(r.message);
        }
    }, getSessionId());
}

export {createAccount, changePassword, deactivateAccount, activateAccount, deleteAccount, getAccounts, getAccountInfo, getAccountTypes, isDeleteable}