import React, { useEffect, useRef, useState } from "react";
import ActivateIcon from "../../assets/icons/activate";
import DeactivateIcon from "../../assets/icons/deactivate";
import DeleteIcon from "../../assets/icons/delete";
import IconButton from "../../components/iconButton";
import MultipleSelect from "../../components/multipleSelect";

import TextField from "../../components/textField";
import { getClubCategories, setHideClub } from "../../content/clubManager";
import { submitClubChange } from "../dashboardClub";
import { openActionPage } from "../../components/actionPage";
import AddClubCategoryAction from "../action/addClubCategory";
import { getCategory } from "../../content/loginManager";

function ClubGeneral(props) {

    const firstExecute = useRef(false);
    const [categories, setCategories] = useState([]);
    const [hiddenChanged, setHiddenChanged] = useState(false);
    useEffect(() => {
        if(props.data && firstExecute.current == false) {
            getClubCategories(setCategories);
            firstExecute.current = true;
        }
    })

    if(!props.data || !categories) {
        return <></>;
    }

    var hidden = props.data.hidden;
    if(hiddenChanged) {
        hidden = !hidden;
    }

    return <div className="general">

        
        <TextField defaultValue={props.data.name} onChange={(v) => {
            submitClubChange("name", v);
        }} placeholder="Anzeigename eingeben" label="Anzeigename"/>
        <TextField defaultValue={props.data.title} onChange={(v) => {
            submitClubChange("title", v);
        }} placeholder="Titel eingeben" label="Titel"/>
        <TextField defaultValue={props.data.subtitle} onChange={(v) => {
            submitClubChange("subtitle", v);
        }}  placeholder="Slogan eingeben" label="Slogan"/>
        <MultipleSelect onAdd={() => {
            openActionPage(<AddClubCategoryAction/>);
        }} add="+ Kategorie hinzufügen" editable={getCategory() == "ADMIN" || getCategory() == "SUPERADMIN"} defaultValue={categories.indexOf(props.data.category)} items={categories} onChange={(v) => {
            submitClubChange("category", categories[v]);
        }} label="Kategorie"/>

        <div className="icons">
            <IconButton onClick={() => {
                setHideClub(props.data.id, !hidden, () => {
                    setHiddenChanged(!hiddenChanged);
                });
            }}>{hidden ? <ActivateIcon/> : <DeactivateIcon/>}</IconButton>
            <p>Vereinsseite {hidden ? "einblenden" : "ausblenden"}</p>
        </div>
    </div>;

}

export default ClubGeneral;