import React from "react";
import {useNavigate} from "react-router-dom";
import Page from "./page";

import "../style/createDashboardPage.css";
import Button from "./button";
import ArrowBigIcon from "../assets/icons/arrowBig";
import ButtonBack from "./buttonBack";
import ButtonForward from "./buttonForward";

export default function CreateDashboardPage(props) {

    const navigate = useNavigate();
    
    return <Page className={"create-page" + (props.className ? " " + props.className : "")} id={props.id ? props.id : ""}>
        <div className="main-box">
            
            <ButtonBack className={"cancel" + (props.stepBack ? " hidden" : "")} onClick={() => {
                if(props.stepBack) {
                    return;
                }
                    navigate("/" + props.back);
                }}/>
            <h1>{props.title}</h1>
            <div className="content">
            {props.children}
            </div>
            {props.stepBack ? <ButtonForward back className="finish back" title={props.stepBack} onClick={props.onStepBack}/> : <></>}
            <ButtonForward className="finish" onClick={props.onClick} title={props.finishButton}/>
        </div>
    </Page>;
}